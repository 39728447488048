.login-body {
  background: @at_color_white;
  background-size: cover;
  width: 100%;
  width: 100vw;
  height: 100%;
  height: 100vh;

  .container {
    width: 100%;
    width: 100vw;
    height: 100%;
    height: 100vh;

    display: flex;
    align-content: center;
    justify-content: center;
  }
}

.form-signin {
  max-width: 412px;
  flex-basis: 412px;
  flex-grow: 0;
  flex-shrink: 0;
  margin: auto;
  background: @at_color_white;
  border-radius: 3px;
  box-shadow: 0 0 150px 0 rgba(0, 0, 0, 0.1);
}

.form-signin-heading {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  font-weight: 100;
  color: #b0b9c0;
  margin: 60px 0;

  .avatar {
    width: 80px;
    height: 80px;
    margin-bottom: 7px;

    .cls-1 {
      fill: none;
    }
    .cls-2 {
      fill: @at_color_teal;
      opacity: 0.1;
    }
    .cls-3 {
      clip-path: url(#clip-path);
    }
    .cls-4 {
      fill: @at_color_teal;
    }
  }
}

.form-signin .alert {
  border: 0;
  padding: 20px;
  margin-bottom: 0;
  border-radius: 3px;
  color: #ef5b5b;
  background: #fdefef;

  text-align: center;
  position: relative;

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .request-id {
    display: block;
    margin-top: 1.2em;
  }
}

.form-signin .checkbox {
  margin-bottom: 14px;
}

.form-signin .checkbox {
  font-weight: normal;
  color: #b6b6b6;
  font-weight: 300;
  font-family: @at_font_primary;
}

.form-signin .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 7px 10px 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="text"], .form-signin input[type="password"] {
  border: none;
  box-shadow: none;
  font-size: 14px;
  .placeholder(#cad0d5);
  color: #7b8a96;
}

.form-signin .btn-login {
  &:extend(.btn-primary);
  margin: 25px 0;
  padding: 12px;
  font-weight: 600;
}

.form-signin .links p {
  text-align: center;
  font-size: .9em;
  margin-bottom: 0;
}

.login-wrap {
  padding: 0 60px 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: -20px;
}

.user-login-info {
  .alert-error + & {
    order: -1;
  }
}

.alert-error ~ .btn-login {
  margin-top: 20px;
}

.user-login-row {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border-bottom: 2px solid #e5e8ea;
  margin: 1.3em 0;

  label {
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 24px;
    padding: 0 16px;
  }

  .login-email-label {
    font-size: 16px;
  }

  .login-password-label {
    font-size: 22px;
  }

  .fa {
    color: #165297;
    transition: all .3s;
  }

  input:focus + label .fa {
    //color: #7b8a96;
  }

  .alert-error + .user-login-info & {
    border-color: #ef5b5b;

    label .fa {
      color: #ef5b5b;
    }
  }
}

.user-login-info .form-control:last-child {
  margin-bottom: 0 !important;
}

.registration {
  position: absolute;
  right: 0;
  bottom: -35px;
  color: #b8bab9;
}
