a {
  color: var(--color-primary);

  &:focus,
  &:hover {
    color: var(--color-primary-darker);
  }
}

.timeline .timeline-icon.green {
  background-color: var(--color-primary);
}

.timeline .timeline-icon.blue {
  background-color: var(--color-primary-brighter);
}

.timeline-desk {
  h1.green,
  span a.green,
  a {
    color: var(--color-primary);
  }

  h1.blue,
  span a.blue {
    color: var(--color-primary-brighter);
  }
}

.topside-navigation {
  background: var(--color-primary);

  a {
    background: var(--color-primary);

    &.active {
      background: var(--color-primary-darkest);
    }

    &:hover,
    &:focus {
      background: var(--color-primary-darker);
    }
  }

  .sub li.active a {
    background: var(--color-primary-darkest);

    &:hover,
    &:focus {
      background: var(--color-primary-darker);
    }
  }
}

.btn-primary,
.form-signin .btn-login {
  background-color: var(--color-primary);
  border-color: var(--color-primary-darker);

  &:focus,
  &.focus,
  &:hover,
  &:active,
  &.active {
    background-color: var(--color-primary-lighter);
    border-color: var(--color-primary);
  }
}

.user-login-row .fa {
  color: var(--color-primary);
}

.panel-info > .panel-heading {
  background-color: var(--color-primary-lighter);
}

.profile-information .profile-desk h1 {
  color: var(--color-primary);
}

.customer-contact {
  .btn-primary,
  .loan-lead-application .documents-heading li a-primary,
  .loan-lead-application .documents-heading li span-primary {
    color: var(--color-primary);
  }
}

.chosen-container-active .chosen-choices {
  border-color: var(--color-primary);
  box-shadow: var(--box-shadow-primary);
}

.chosen-container {
  .chosen-results {
    li {
      &.highlighted {
        background-color: var(--color-primary);
      }
    }
  }
}

.customer-tasks-list-group .customer-tasks-list-group-item .btn-primary,
.header .dropdown-menu.extended.inbox .customer-tasks-list-group .customer-tasks-list-group-item .btn-primary,
.customer-tasks-list-group .customer-tasks-list-group-item .loan-lead-application .documents-heading li a-primary,
.header .dropdown-menu.extended.inbox .customer-tasks-list-group .customer-tasks-list-group-item .loan-lead-application .documents-heading li a-primary,
.customer-tasks-list-group .customer-tasks-list-group-item .loan-lead-application .documents-heading li span-primary,
.header .dropdown-menu.extended.inbox .customer-tasks-list-group .customer-tasks-list-group-item .loan-lead-application .documents-heading li span-primary {
  color: var(--color-primary);
}

.label-info,
.label-primary {
  color: var(--color-primary-lighter);
  background-color: var(--color-primary-lightest);
}

.label-success {
  color: #fff;
  background-color: #61d352;
}

.loan-lead-navbar .nav li a.active {
  color: var(--color-primary);
}

.dropdown-menu.extended.logout > li {
  & > a:hover,
  & > a:focus {
    i {
      color: var(--color-primary);
    }
  }
}

.brand {
  color: var(--color-primary);
}

.prf-border-head {
  color: var(--color-primary);
}

.prf-contacts {
  h2 {
    color: var(--color-primary);

    span {
      background-color: var(--color-primary);
    }
  }
}

.pagination > .active {
  & > span,
  & > span:hover,
  & > span:focus,
  & > a,
  & > a:hover,
  & > a:focus {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
}

.btn-link {
  color: var(--color-primary);
}

.bootstrap-datetimepicker-widget table td.today {
  &.active::before {
    border-color: #fff #fff transparent transparent;
  }

  &::before {
    border-top-color: var(--color-primary);
    border-bottom-color: var(--color-primary);
  }
}

.daterangepicker,
.datepicker {
  td.active,
  td.active:active,
  td.active:hover,
  td.active:hover:hover,
  td.active:hover:active,
  td.active.active,
  td.active.active:hover,
  td.active.disabled,
  td.active.disabled:hover,
  td.active[disabled],
  td.active[disabled]:hover,
  td span.active:hover,
  td span.active:active,
  td span.active.active,
  td span.active.disabled,
  td span.active[disabled] {
    background-color: var(--color-primary);
  }

  .today {
    &.active::before {
      border-color: #fff #fff transparent transparent;
    }

    &::before {
      border-color: var(--color-primary) var(--color-primary) transparent transparent;
    }
  }
}

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.operator-label.label-active {
  background-color: var(--color-primary);
}

input[type=checkbox] {
  appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #666;
  transition-duration: 200ms;
  transition-property: background, border;
  transition-timing-function: ease-in-out;

  &:focus {
    outline: none;
  }

  &:checked {
    background-color: var(--color-primary);
    border-color: var(--color-primary);

    &::after {
      content: '';
      display: block;
      position: relative;
      left: 0.2em;
      top: 0.025em;
      width: 0.4em;
      height: 0.65em;
      border: solid white;
      border-width: 0 0.215em 0.215em 0;
      transform: rotate(45deg);
    }
  }
}

.form-control:focus {
  border-color: var(--color-primary);
  box-shadow: var(--box-shadow-primary);
}

.loan-applications-table-container > .la-table > .la-table-row > .col-detail .col-detail-heading {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.sf-error-toolbar {
  opacity: 0.5;
  pointer-events: none;
}

.display-engine-view[display-engine-view="application_status_declined"],
.display-engine-view[display-engine-view="application_status_review"] {
  a {
    background-color: var(--color-secondary-lightest);
  }
}

.new-navbar {
  .header {
    box-shadow: none;
    background: none;

    header,
    .topside-navigation {
      display: none;
    }

    #navbar:empty {
      background-color: var(--color-primary);
      color: rgba(255, 255, 255, 1);
      min-height: var(--topbar-height);
    }
  }
}
