.password-reset-body {
    background: #fff;
    background-size: cover;
    width: 100%;
    width: 100vw;
    height: 100%;
    height: 100vh;

    .container {
        width: 100%;
        width: 100vw;
        height: 100%;
        height: 100vh;

        display: flex;
        align-content: center;
        justify-content: center;
    }
}

.form-reset {
    max-width: 412px;
    flex-basis: 412px;
    flex-grow: 0;
    flex-shrink: 0;
    margin: auto;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 0 150px 0 rgba(0, 0, 0, 0.1);

    .row {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        border-bottom: 2px solid #e5e8ea !important;

        .alert-error + .user-login-info & {
            border-color: #ef5b5b;
        }
    }

    .btn-submit {
        margin-top: 20px;
        background-color: #165297;
        border-color: #134681;
        color: #fff;
    }
}

.form-reset-heading {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 100;
    color: #b0b9c0;
    margin: 45px 0;

    .avatar {
      width: 80px;
      height: 80px;
      margin-bottom: 7px;

      .cls-1 {
        fill: none;
      }
      .cls-2 {
        fill: #f2f6fa;
      }
      .cls-3 {
        clip-path: url(#clip-path);
      }
      .cls-4 {
        fill: #93a4b0;
      }
    }
}

.form-reset .alert {
    border: 0;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 3px;
    color: #ef5b5b;
    background: #fdefef;

    text-align: center;
    position: relative;

    .close {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .request-id {
        display: block;
        margin-top: 1.2em;
    }
}

.form-reset .form-control:focus {
    z-index: 2;
}

.form-reset input[type="text"],
.form-reset input[type="password"],
.form-reset input[type="email"] {
    border: none;
    box-shadow: none;
    font-size: 14px;
    color: #7b8a96;
}

.form-wrapper {
    padding: 0 60px 60px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: -20px;
}
