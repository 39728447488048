.form-signin .alert {

    .close {
        position: absolute;
        right: 8px;
        top: 5px;
    }

    &.alert-info {
        background-color: #ecf1f7 !important;
        border-color: #d4e3ec !important;
        color: #375d89 !important;
    }
}

@import "theme-overrides";
